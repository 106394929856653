import '../css/App.css'

function About() {
    return (
        <div>
            <h3 class="pageTitle">About Me</h3>
            <div class="content">
                <p>Hello, I am Vimal. I am interested in many things computational. I do game development, physics simulations, and machine learning. 
                    I am also interested in physics engines that are used in video games. 
                    I hope to learn more about computer simulations of physical systems and high performance computing using CUDA.</p>
                <p>I also have some experience with backend web development by developing a Discord bot using Discord.js</p>
                <p>I have graduated with MSc in Physics from IISER Thiruvananthapuram in 2024. </p>

            </div>
        </div>
    )
}

export default About;