import '../css/App.css'

function Header() {
   return ( <div class="header">
            <h1>Vimal's Portfolio</h1>
        </div>
   );
}

export default Header;
