import '../css/App.css'
import Header from './Header.jsx'
import MenuBar from './MenuBar.jsx'

function Layout() {
    return (
        <div className="App">
        <Header />
        <MenuBar />
        </div>
    )
}

export default Layout;