import '../css/App.css'

function Contacts(){
    return (<div>
        
        <h3 class="pageTitle">Contacts</h3>
            <div class="content">
                <p>Github: <a href="https://github.com/rally0078">Rally0708 at github</a></p>
                <p>Email: <a href="mailto:vimaljaideep009@gmail.com">vimaljaideep009@gmail.com</a></p>
                <p>Discord: rally0078</p>
                <p>Steam: <a href="https://steamcommunity.com/id/rally0078/">Steam Profile</a></p>
            </div>
        </div>
        )
}

export default Contacts;